import React, { useEffect, useState, } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './HomepageSlider.module.scss';
import HomepageSliderControllers from './HomepageSliderControllers';

const HomepageSlider = (props) => {
  const { sliderData, items, isHomepage = true } = props;
  const [pageWidth, setPageWidth] = useState(0);

  const isDesktop = pageWidth >= 768;
  const activeSliderData = isDesktop ? sliderData.desktop : sliderData.mobile;

  const horizontalPadding = () => {
    if (!isHomepage) {
      return activeSliderData.horizontalPadding ?? 0
    }
    return isDesktop ? ((pageWidth - 1120) / 2) : 16;
  };

  useEffect(() => {
    setPageWidth(window.innerWidth)
  }, []);

  const visibleItems = activeSliderData.visibleItems ?? ((pageWidth - (activeSliderData.outterHorizontalPadding ?? horizontalPadding() ?? 0) * 2) / activeSliderData.width)

  return (
    <CarouselProvider
      naturalSlideHeight={activeSliderData.height}
      naturalSlideWidth={activeSliderData.width}
      totalSlides={items.length}
      visibleSlides={visibleItems}
      dragEnabled={false}
      infinite={true}
      isIntrinsicHeight={true}
    >
      <Slider
        className='slider-container'
        style={{
          padding: `0 ${horizontalPadding()}px`,
        }}
      >
        {items.map((item, index) => (
          <Slide
            index={index}
            onFocus={null}
            key={`slide-item-${index}`}
          >
            {(item)}
          </Slide>
        ))}
      </Slider>
      <HomepageSliderControllers
        horizontalPadding={horizontalPadding()}
      />
    </CarouselProvider>
  );
}

export default HomepageSlider;